import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
// cmp
import PagePanel from "../cmp/page-panel";
import SubPage from "../cmp/playground/sub-page";
import ActionBuilder from "../cmp/playground/action-builder";
import ActionResult from "../cmp/playground/action-result";
import ResponsiveLayout from "../cmp/responsive-layout";
import PlaygroundCard from "../cmp/playground/playground-card";
// services
import Gupport from "../services/gupport";
import { sortAlphabetically } from "../services/utils";

class GupportPlaygroundPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// ready: Gupport.ready,
			schemaId: null,
			actionJson: "{}",
			activePage: "action-send",
			actionLoading: false,
			actionResultPayload: undefined,
			actionResultError: undefined,
			responseListenerId: null,
			apiSchemas: Gupport.apiSchemas,
			rbacs: this.sort(Gupport.apiSchemas)
		};

		this.handleGupportReady = this.handleGupportReady.bind(this);
		this.onGupportResponse = this.onGupportResponse.bind(this);
		this.handleActionChange = this.handleActionChange.bind(this);
		this.handleActionJsonChange = this.handleActionJsonChange.bind(this);
		this.handleOnFocus = this.handleOnFocus.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.handleBack = this.handleBack.bind(this);
		this.sort = this.sort.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
	}

	componentDidMount() {
		Gupport.on("ready", this.handleGupportReady);
	}

	componentWillUnmount() {
		Gupport.off("ready", this.handleGupportReady);
		this.clearResponseListener();
	}

	handleGupportReady() {
		this.setState({
			// ready: Gupport.ready,
			apiSchemas: Gupport.apiSchemas,
			rbacs: this.sort(Gupport.apiSchemas)
		});
	}

	sort(apiSchemas) {
		if (apiSchemas.length === 0) {
			return [];
		}

		const rbacs = [{
			name: "unknown",
			apis: []
		}];

		apiSchemas.forEach((apiSchema) => {
			const apiRbac = apiSchema.data.rbac || "unknown";
			let foundRbac = rbacs.find((rbac) => (rbac.name === apiRbac));
			if (!foundRbac) {
				foundRbac = {
					name: apiRbac,
					apis: []
				};
				rbacs.push(foundRbac);
			}
			foundRbac.apis.push(apiSchema);
		});

		this.sortRbac(rbacs);
		rbacs.forEach((rbac) => {
			this.sortApis(rbac.apis);
		});

		return rbacs;
	}

	sortRbac(rbacs) {
		rbacs.sort((a, b) => (sortAlphabetically(a.name.toLowerCase(), b.name.toLowerCase())));
	}

	sortApis(apiSchemas) {
		apiSchemas.sort((a, b) => (sortAlphabetically(a.data.label.toLowerCase(), b.data.label.toLowerCase())));
	}

	onGupportResponse(error, msg) {
		if (!error && msg.payload.status !== "ok") {
			error = new Error(JSON.stringify(msg.payload, null, 2));
		}
		this.setState({
			actionLoading: false,
			actionResultPayload: error ? undefined : msg.payload,
			actionResultError: error
		});
	}

	clearResponseListener() {
		if (this.state.responseListenerId) {
			Gupport.clearResponseListener(this.state.responseListenerId);
		}
	}

	handleActionChange(schemaId, json) {
		this.setState({
			dialogOpen: false,
			schemaId: schemaId,
			actionJson: json,
			activePage: "action-send"
		});
	}

	handleActionJsonChange(event) {
		this.setState({
			actionJson: event.target.value
		});
	}

	handleOnFocus(event) {
		if (this.state.actionJson === "{}") {
			event.target.setSelectionRange(1, 1);
		}
	}

	handleSend() {
		this.clearResponseListener();
		const listenerId = Gupport.send(JSON.parse(this.state.actionJson));
		Gupport.setResponseListener(listenerId, this.onGupportResponse);

		this.setState({
			actionLoading: true,
			activePage: "action-result",
			responseListenerId: listenerId
		});
	}

	handleBack(page) {
		this.setState({
			activePage: page
		});
	}

	openDialog(dialog) {
		this.setState({
			dialogOpen: dialog
		});
	}

	handleDialogClose() {
		this.setState({
			dialogOpen: false
		});
	}

	render() {
		const { t } = this.props;
		const actionSend = (
			<>
				<PlaygroundCard title={t("playground.json")}>
					<TextField
						autoFocus={true}
						placeholder="{}"
						fullWidth={true}
						multiline={true}
						minRows={5}
						value={this.state.actionJson}
						onChange={this.handleActionJsonChange}
						onFocus={this.handleOnFocus}
					/>
				</PlaygroundCard>
				<div>
					<Button
						color="neutral"
						style={{ float: "left" }}
						onClick={this.openDialog.bind(this, "templates")}
					>
						{t("playground.templates")}
					</Button>
					<Button
						disabled={!this.state.schemaId}
						style={{ float: "right" }}
						onClick={this.handleSend}
					>
						{t("messages.send")}
					</Button>
				</div>
				<Dialog
					fullWidth={true}
					open={this.state.dialogOpen === "templates"}
					onClose={this.handleDialogClose}
				>
					<DialogTitle>{t("playground.templates")}</DialogTitle>
					<DialogContent>
						<ActionBuilder
							schemas={this.state.apiSchemas}
							rbacs={this.state.rbacs}
							onChange={this.handleActionChange}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleDialogClose}>{t("dialog.ok")}</Button>
					</DialogActions>
				</Dialog>
			</>
		);
		const actionDetails = (
			<PlaygroundCard title={t("playground.details")}>
				<ActionResult
					loading={this.state.actionLoading}
					error={this.state.actionResultError}
					payload={this.state.actionResultPayload}
				/>
			</PlaygroundCard>
		);

		return (
			<ResponsiveLayout>
				<PagePanel screen="xs" activePage={this.state.activePage} title={t("playground.titleGupport")} activeRoute="/playground/gupport">
					<SubPage width="100%" page="action-send">
						{actionSend}
					</SubPage>
					<SubPage width="100%" page="action-result" onBack={this.handleBack.bind(this, "action-send")}>
						{actionDetails}
					</SubPage>
				</PagePanel>
				<PagePanel screen="sm" title={t("playground.titleGupport")} activeRoute="/playground/gupport">
					<SubPage width="33%">
						{actionSend}
					</SubPage>
					<SubPage width="67%">
						{actionDetails}
					</SubPage>
				</PagePanel>
				<PagePanel screen="lg" title={t("playground.titleGupport")} activeRoute="/playground/gupport" navigationDrawerDocked={true}>
					<SubPage width="33%">
						{actionSend}
					</SubPage>
					<SubPage width="67%">
						{actionDetails}
					</SubPage>
				</PagePanel>
			</ResponsiveLayout>
		);
	}

}

GupportPlaygroundPage.propTypes = {
	t: PropTypes.func.isRequired
};

export default withTranslation()(GupportPlaygroundPage);
