import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import GLogTable from "../cmp/logs/glog-table";

type Props = {
	hideAppBar: boolean;
};

const GLogPage = (props: Props) => {
	const { t } = useTranslation();

	return (
		<ResponsivePagePanel title={t("glogs.title")} activeRoute="/glogs" hideAppBar={props.hideAppBar}>
			<GLogTable />
		</ResponsivePagePanel>
	);
};

GLogPage.defaultProps = {
	hideAppBar: false
};

GLogPage.propTypes = {
	hideAppBar: PropTypes.bool
};

export default GLogPage;
