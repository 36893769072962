const TemplateJsons = {
	dt_gateway: {
		category: "dc_gateway",
		color: "#AAAAAAFF",
		device_details_native: true,
		endpoint: "00",
		help: null,
		icon: "di_gateway",
		icon_a: "di_gateway_a",
		icon_b: "di_gateway_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		plugin: "nuki",
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_gateway"
	},
	dt_camera: {
		category: "dc_cameras",
		color: "#988FD8FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_camera",
		icon_a: "di_camera_a",
		icon_b: "di_camera_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_motion_sensors",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_camera_indoor"
	},
	dt_smart_bulb_white: {
		category: "dc_lights",
		color: "#EACE73FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_smart_bulb_white",
		icon_a: "di_smart_bulb_white_a",
		icon_b: "di_smart_bulb_white_b",
		icon_blink: false,
		is_presence: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_lights",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_smart_bulb_white"
	},
	dt_smart_bulb_white_tunable: {
		category: "dc_lights",
		color: "#EACE73FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_smart_bulb_white_tunable",
		icon_a: "di_smart_bulb_white_tunable_a",
		icon_b: "di_smart_bulb_white_tunable_b",
		icon_blink: false,
		is_presence: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_lights",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_smart_bulb_white_tunable"
	},
	dt_smart_bulb_color: {
		category: "dc_lights",
		color: "#EACE73FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_smart_bulb_color",
		icon_a: "di_smart_bulb_color_a",
		icon_b: "di_smart_bulb_color_b",
		icon_blink: false,
		is_presence: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_lights",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_smart_bulb_color"
	},
	dt_camera_indoor: {
		category: "dc_cameras",
		color: "#988FD8FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_camera",
		icon_a: "di_camera_a",
		icon_b: "di_camera_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_motion_sensors",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_camera_indoor"
	},
	dt_camera_outdoor: {
		category: "dc_cameras",
		color: "#988FD8FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_camera",
		icon_a: "di_camera_a",
		icon_b: "di_camera_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_motion_sensors",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_camera_outdoor"
	},
	dt_camera_pantilt: {
		category: "dc_cameras",
		color: "#988FD8FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_camera",
		icon_a: "di_camera_a",
		icon_b: "di_camera_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_motion_sensors",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_camera_pantilt"
	},
	dt_smart_plug: {
		category: "dc_smart_plugs",
		color: "#85C98AFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_smart_plug",
		icon_a: "di_smart_plug_a",
		icon_b: "di_smart_plug_b",
		icon_blink: false,
		is_presence: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_plugs",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_smart_plug"
	},
	dt_water_sensor: {
		category: "dc_pom",
		color: "#0FC3E8FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_water_sensor",
		icon_a: "di_water_sensor_a",
		icon_b: "di_water_sensor_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_pom",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_water_sensor"
	},
	dt_contact_sensor: {
		category: "dc_pom",
		color: "#BC6F83FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_contact_sensor",
		icon_a: "di_contact_sensor_a",
		icon_b: "di_contact_sensor_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_contact_sensors",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_contact_sensor"
	},
	dt_co_sensor: {
		category: "dc_pom",
		color: "#189690FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_co_sensor",
		icon_a: "di_co_sensor_a",
		icon_b: "di_co_sensor_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_pom",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_co_sensor"
	},
	dt_smoke_sensor: {
		category: "dc_pom",
		color: "#B2937BFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_smoke_sensor",
		icon_a: "di_smoke_sensor_a",
		icon_b: "di_smoke_sensor_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_pom",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_smoke_sensor"
	},
	dt_motion_sensor: {
		category: "dc_pom",
		color: "#3EC9A7FF",
		device_details_native: false,
		endpoint: "01",
		help: null,
		icon: "di_motion_sensor",
		icon_a: "di_motion_sensor_a",
		icon_b: "di_motion_sensor_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_motion_sensors",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_motion_sensor"
	},
	dt_smart_button: {
		category: "dc_pom",
		color: "#7AA7D6FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_smart_button",
		icon_a: "di_smart_button_a",
		icon_b: "di_smart_button_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_smart_button"
	},
	dt_push_button: {
		category: "dc_comfort",
		color: "#7AA7D6FF",
		device_details_native: true,
		endpoint: "01",
		icon: "di_push_button",
		icon_a: "di_push_button_a",
		icon_b: "di_push_button_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_push_button"
	},
	dt_panic_button: {
		category: "dc_pom",
		color: "#7AA7D6FF",
		device_details_native: true,
		endpoint: "01",
		icon: "di_panic_button",
		icon_a: "di_panic_button_a",
		icon_b: "di_panic_button_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_panic_button"
	},
	dt_room_sensor: {
		category: "dc_comfort",
		color: "#FF9C5BFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_room_sensor",
		icon_a: "di_room_sensor_a",
		icon_b: "di_room_sensor_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_room_sensor"
	},
	dt_siren: {
		category: "dc_pom",
		color: "#FB6066FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_siren",
		icon_a: "di_siren_a",
		icon_b: "di_siren_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_siren"
	},
	dt_door_lock: {
		category: "dc_access",
		color: "#FF4E50FF",
		device_details_native: true,
		endpoint: "02",
		help: null,
		icon: "di_door_lock",
		icon_a: "di_door_lock_a",
		icon_b: "di_door_lock_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_door_locks",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_door_lock"
	},
	dt_orbit_1: {
		RxOnWhenIdle: true,
		category: "dc_irrigation",
		color: "#006666FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_orbit_1",
		icon_a: "di_orbit_1_a",
		icon_b: "di_orbit_1_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_orbit_1"
	},
	dt_orbit_12: {
		category: "dc_irrigation",
		color: "#006666FF",
		device_details_native: true,
		endpoint: "64",
		help: null,
		icon: "di_orbit_12",
		icon_a: "di_orbit_12_a",
		icon_b: "di_orbit_12_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_orbit_12"
	},
	dt_water_valve: {
		category: "dc_irrigation",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_water_valve",
		icon_a: "di_water_valve_a",
		icon_b: "di_water_valve_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_water_valve"
	},
	dt_light_sensor: {
		category: null,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}"
	},
	dt_glass_break_sensor: {
		RxOnWhenIdle: false,
		category: "dc_pom",
		color: "#76E6D1FF",
		device_details_native: true,
		endpoint: "01",
		icon: "di_glass_break_sensor",
		icon_a: "di_glass_break_sensor_a",
		icon_b: "di_glass_break_sensor_b",
		icon_blink: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: "qc_pom",
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_glass_break_sensor"
	},
	dt_heater: {
		category: "dc_comfort",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_heater",
		icon_a: "di_heater_a",
		icon_b: "di_heater_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_heater"
	},
	dt_relay: {
		category: "dc_lights",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		icon: "di_wall_switch",
		icon_a: "di_wall_switch_a",
		icon_b: "di_wall_switch_b",
		icon_blink: false,
		is_presence: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_relay"
	},
	dt_range_extender: {
		category: "dc_network",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		icon: "di_range_extender",
		icon_a: "di_range_extender_a",
		icon_b: "di_range_extender_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_range_extender"
	},
	dt_shades_controller: {
		category: "dc_shade",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_shades_controller",
		icon_a: "di_shades_controller_a",
		icon_b: "di_shades_controller_b",
		icon_blink: false,
		is_presence: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		stringOverrideKey: "shades_controller",
		tags: null,
		type: "dt_shades_controller"
	},
	dt_speaker: {
		category: "dc_entertainment",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_speaker",
		icon_a: "di_speaker_a",
		icon_b: "di_speaker_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_speaker"
	},
	dt_thermostat: {
		category: "dc_comfort",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_thermostat",
		icon_a: "di_thermostat_a",
		icon_b: "di_thermostat_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_thermostat"
	},
	dt_video_doorbell: {
		category: "dc_access",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_video_doorbell",
		icon_a: "di_video_doorbell_a",
		icon_b: "di_video_doorbell_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_video_doorbell"
	},
	dt_wall_switch: {
		category: "dc_comfort",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_wall_switch",
		icon_a: "di_wall_switch_a",
		icon_b: "di_wall_switch_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_wall_switch"
	},
	dt_power_clamp: {
		category: "dc_smart_plugs",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_power_clamp",
		icon_a: "di_power_clamp_a",
		icon_b: "di_power_clamp_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_power_clamp"
	},
	dt_voice_pad: {
		category: "dc_entertainment",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_voice_pad",
		icon_a: "di_voice_pad_a",
		icon_b: "di_voice_pad_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_voice_pad"
	},
	dt_lawn_mower: {
		category: "dc_robot",
		color: "#B0BBBFFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_lawn_mower",
		icon_a: "di_lawn_mower_a",
		icon_b: "di_lawn_mower_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		plugin: "gardena",
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_lawn_mower"
	},
	dt_ir_controller: {
		category: "dc_comfort",
		color: "#FF9C5BFF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_remote_control",
		icon_a: "di_remote_control_a",
		icon_b: "di_remote_control_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_ir_controller"
	},
	dt_group_bulb: {
		category: "dc_groups",
		color: "#B8A361FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_group_bulb",
		icon_a: "di_group_bulb_a",
		icon_b: "di_group_bulb_b",
		icon_blink: false,
		is_group: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_group_bulb"
	},
	dt_group_bulb_color: {
		category: "dc_groups",
		color: "#B8A361FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_group_bulb_color",
		icon_a: "di_group_bulb_color_a",
		icon_b: "di_group_bulb_color_b",
		icon_blink: false,
		is_group: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_group_bulb_color"
	},
	dt_group_plug: {
		category: "dc_groups",
		color: "#B8A361FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_group_plug",
		icon_a: "di_group_plug_a",
		icon_b: "di_group_plug_b",
		icon_blink: false,
		is_group: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_group_plug"
	},
	dt_group_lock: {
		category: "dc_groups",
		color: "#B8A361FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_group_lock",
		icon_a: "di_group_lock_a",
		icon_b: "di_group_lock_b",
		icon_blink: false,
		is_group: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_group_lock"
	},
	dt_group_mixed: {
		category: "dc_groups",
		color: "#B8A361FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_group_mixed",
		icon_a: "di_group_mixed_a",
		icon_b: "di_group_mixed_b",
		icon_blink: false,
		is_group: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_group_mixed"
	},
	dt_group_empty: {
		category: "dc_groups",
		color: "#B8A361FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_group_empty",
		icon_a: "di_group_empty_a",
		icon_b: "di_group_empty_b",
		icon_blink: false,
		is_group: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_group_empty"
	},
	dt_group_thermostat: {
		category: "dc_groups",
		color: "#B8A361FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_group_thermostat",
		icon_a: "di_group_thermostat_a",
		icon_b: "di_group_thermostat_b",
		icon_blink: false,
		is_group: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		tags: null,
		type: "dt_group_thermostat"
	},
	dt_group_shades: {
		category: "dc_groups",
		color: "#B8A361FF",
		device_details_native: true,
		endpoint: "01",
		help: null,
		icon: "di_group_shades",
		icon_a: "di_group_shades_a",
		icon_b: "di_group_shades_b",
		icon_blink: false,
		is_group: true,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		quickview_category: null,
		rocid: "{{ROCID}}",
		stringOverrideKey: "shades_controller",
		tags: null,
		type: "dt_group_shades"
	},
	dt_fan: {
		RxOnWhenIdle: false,
		category: "dc_comfort",
		color: "#76E6D1FF",
		device_details_native: true,
		endpoint: "01",
		icon: "di_fan",
		icon_a: "di_fan_a",
		icon_b: "di_fan_b",
		icon_blink: false,
		mfg_fw: null,
		mfg_help: null,
		mfg_model: null,
		mfg_name: "{{DEVICE_NAME}}",
		pairing_category: null,
		pairing_help: null,
		pairing_icon: null,
		pairing_name: null,
		pairing_video: null,
		rocid: "{{ROCID}}",
		stringOverrideKey: "fan",
		tags: null,
		type: "dt_fan"
	}
};

export default TemplateJsons;
