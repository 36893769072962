import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Paper,
	Button,
	TextField,
	ListItemButton,
	ListItemText,
	Radio,
	RadioGroup,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControlLabel
} from "@mui/material";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
// services
import Settings from "../services/settings";
// import Glient from "../services/glient";
// types
import type { Channel, ConfigCluster } from "../types/config";

const SettingsPage = () => {
	const { t } = useTranslation();
	const [openClusterDialog, setOpenClusterDialog] = useState(false);
	const [openChannelDialog, setOpenChannelDialog] = useState(false);
	const [cluster, setCluster] = useState<ConfigCluster>(Settings.cluster);
	const [channel, setChannel] = useState<Channel>(Settings.channel);

	// const resetDefaultSettings = () => {
	// 	Settings.resetDefault();
	// 	Settings.save();
	// 	setCluster(Settings.cluster);
	// };

	const handleClusterChange = (event, value) => {
		const clusterId = value || event.target.value;
		const currentCluster = Settings.clusters.find((cluster) => cluster.id === clusterId);

		setCluster(currentCluster);
		Settings.cluster = currentCluster;
		Settings.save();
	};

	const handleChannelChange = (event) => {
		const selectedChannel = event.target.value;
		setChannel(selectedChannel);
	};

	const handleChannelDialogClose = () => {
		setOpenChannelDialog(false);
		Settings.channel = channel;
		Settings.save();
		// Glient.reconnect();
	};

	const clusters: Array<ConfigCluster> = Settings.clusters || [];

	return (
		<ResponsivePagePanel title={t("settings.title")} activeRoute="/settings">
			<section>
				<h4>{t("settings.cluster")}</h4>
				<Paper>
					<ListItemButton onClick={() => (setOpenClusterDialog(true))}>
						<ListItemText primary={t("settings.gerver")} secondary={cluster.label} />
					</ListItemButton>
				</Paper>
			</section>
			<section>
				<h4>{t("settings.channel")}</h4>
				<Paper>
					<ListItemButton onClick={() => (setOpenChannelDialog(true))}>
						<ListItemText primary={t("settings.channel")} secondary={channel} />
					</ListItemButton>
				</Paper>
			</section>
			{/*
				<ListItemButton onClick={this.resetDefaultSettings}>
					<ListItemText primary={t("settings.reset} secondary={t("settings.resetSecondary} />
				</ListItemButton>
			*/}
			<Dialog
				fullWidth={true}
				open={openClusterDialog}
				onClose={() => (setOpenClusterDialog(false))}
			>
				<DialogTitle>{t("settings.chooseGerver")}</DialogTitle>
				<DialogContent>
					<RadioGroup name="cluster" value={cluster.id} onChange={handleClusterChange}>
						{clusters.map((cluster) => (
							<FormControlLabel
								key={cluster.id}
								label={cluster.label}
								value={cluster.id}
								control={<Radio />}
							/>
						))}
					</RadioGroup>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => (setOpenClusterDialog(false))}>{t("dialog.ok")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				open={openChannelDialog}
				onClose={handleChannelDialogClose}
			>
				<DialogTitle>{t("settings.setChannel")}</DialogTitle>
				<DialogContent>
					<TextField
						type="text"
						label="Channel"
						fullWidth={true}
						value={channel}
						onChange={handleChannelChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleChannelDialogClose}>{t("dialog.ok")}</Button>
				</DialogActions>
			</Dialog>
		</ResponsivePagePanel>
	);
};

export default SettingsPage;
