import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	AppBar,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	ListItemAvatar,
	Toolbar,
	Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Svg from "../cmp/svg";
// services
import Settings from "../services/settings";
import Providers from "../services/providers";

const AccountAddPage = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	const navigate = useNavigate();
	const params = useParams<"accountFor">();

	const handleLogin = (config) => {
		Providers.login(config, decodeURIComponent(params.accountFor ?? ""));
	};

	return (
		<AppBar>
			<Toolbar>
				<IconButton onClick={() => (navigate("/"))}><Svg src="navigation/arrowLeft.svg" color={theme.palette.primary.contrastText} /></IconButton>
				<Typography variant="h6" noWrap={true} style={{ marginLeft: "12px", width: "100%" }}>
					{t("account.addAccount")}
				</Typography>
				<List style={{ maxWidth: "300px", marginLeft: "auto", marginRight: "auto" }}>
					{Settings.cluster.providers.map((provider) => (
						<ListItemButton key={provider.id} onClick={() => (handleLogin(provider))}>
							<ListItemAvatar><Svg src="navigation/userCircle" /></ListItemAvatar>
							<ListItemText primary={provider.label} secondary={provider.description} />
						</ListItemButton>
					))}
				</List>
			</Toolbar>
		</AppBar>
	);
};

export default AccountAddPage;
