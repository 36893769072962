import { useTranslation } from "react-i18next";
// cmp
import AppDesignSetting from "../cmp/app-design-setting";
import ResponsivePagePanel from "../cmp/responsive-page-panel";

const GeneralSettingsPage = () => {
	const { t } = useTranslation();

	return (
		<ResponsivePagePanel title={t("menu.generalSettings")} activeRoute="/general-settings">
			<AppDesignSetting />
		</ResponsivePagePanel>
	);
};

export default GeneralSettingsPage;
