import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import BackupTabs from "../cmp/backup/BackupTabs";
// services
import Gupport from "../services/gupport";

type Props = {
	hideAppBar: boolean;
};

const BackPage = (props: Props) => {
	const { t } = useTranslation();
	const [ready, setReady] = useState(Gupport.ready);

	useEffect(() => {
		const handleGupportReady = () => {
			setReady(Gupport.ready);
		};

		Gupport.on("ready", handleGupportReady);
		return () => {
			Gupport.off("ready", handleGupportReady);
		};
	}, []);

	return (
		<ResponsivePagePanel title={t("backup.title")} activeRoute="/backups" hideAppBar={props.hideAppBar}>
			{ready ? <BackupTabs /> : <CircularProgress />}
		</ResponsivePagePanel>
	);
};

BackPage.defaultProps = {
	hideAppBar: false
};

BackPage.propTypes = {
	hideAppBar: PropTypes.bool
};

export default BackPage;
