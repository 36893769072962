import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import Image from "../cmp/Image";
// service
// @ts-expect-error: window.appInfo
import AppInfo from "@local/appInfo";

const AboutPage = () => {
	const { t } = useTranslation();

	return (
		<ResponsivePagePanel title={t("menu.about")} activeRoute="/about">
			<Paper style={{padding: "16px"}}>
				<Image
					src="navdrawer-logo.svg"
					sx={{margin: "auto", display: "block", maxHeight: "94px", width: "100%"}}
				/>
				<div style={{ textAlign: "center", marginTop: "24px" }}>{t("generic.build", { build: AppInfo.build })}</div>
			</Paper>
		</ResponsivePagePanel>
	);
};

export default AboutPage;
