import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import LogsTabs from "../cmp/logs/LogsTabs";
import Svg from "../cmp/svg";
// services
import Log from "../services/log";

type Props = {
	separatePage: boolean;
	hideAppBar: boolean;
};

const LogPage = (props: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<ResponsivePagePanel
			activeRoute="/logs"
			title={t("logs.title")}
			hideNavigationDrawer={props.separatePage}
			hideNavigationMenu={props.separatePage}
			hideAppBar={props.hideAppBar}
			iconElementRight={props.hideAppBar ? null :
				<IconButton onClick={() => (Log.openLogWindow())}>
					<Svg src="navigation/openNewWindow.svg" color={theme.palette.primary.contrastText} />
				</IconButton>
			}
		>
			<LogsTabs />
		</ResponsivePagePanel>
	);
};

LogPage.defaultProps = {
	separatePage: false,
	hideAppBar: false
};

LogPage.propTypes = {
	separatePage: PropTypes.bool,
	hideAppBar: PropTypes.bool
};

export default LogPage;
