import { Component } from "react";
import { Paper } from "@mui/material";
// services
import Gupport from "../services/gupport";
import Settings from "../services/settings";
import Providers from "../services/providers";

class LoginPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			redirected: null,
			loading: true
		};
	}

	componentDidMount() {
		const providers = Settings.cluster.providers;
		let providerId = Gupport.account ? Gupport.account.providerId : null;
		if (!providerId && providers && providers.length > 0) {
			providerId = providers[0].id;
		}
		const cfg = providers ? providers.find((provider) => (provider.id === providerId)) : null;
		if (cfg) {
			this.setState({
				redirected: true,
				loading: false
			});
			Providers.login(cfg, Settings.cluster.gupportWsUrl);
		} else {
			this.setState({
				redirected: false,
				loading: false
			});
		}
	}

	render() {
		let message = "Cannot login cause no provider configured!";
		if (this.state.loading) {
			message = "Loading";
		} else if (this.state.redirected) {
			message = "Redirecting...";
		}

		return (
			<div style={{display: "flex", height: window.innerHeight}}>
				{this.state.autoLogin ? null :
					<Paper style={{margin: "auto", padding: "16px", width: "300px"}}>
						{message}
					</Paper>
				}
			</div>
		);
	}

}

export default LoginPage;
