import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
// cmp
import ResponsivePagePanel from "../cmp/responsive-page-panel";
import TEditorSelector from "../cmp/table-editor/teditor-selector";
// services
import Gupport from "../services/gupport";

type Props = {
	hideAppBar?: boolean;
};

const TEditorPage = (props: Props) => {
	const { t } = useTranslation();
	const [ready, setReady] = useState(Gupport.ready);

	useEffect(() => {
		const handleGupportReady = () => {
			setReady(Gupport.ready);
		};

		Gupport.on("ready", handleGupportReady);

		return () => {
			Gupport.off("ready", handleGupportReady);
		};
	}, []);

	return (
		<ResponsivePagePanel title={t("teditor.title")} activeRoute="/teditor" hideAppBar={props.hideAppBar}>
			{
				ready ? <TEditorSelector /> : <CircularProgress />
			}
		</ResponsivePagePanel>
	);
};

TEditorPage.defaultProps = {
	hideAppBar: false
};

TEditorPage.propTypes = {
	hideAppBar: PropTypes.bool
};

export default TEditorPage;
